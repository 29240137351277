@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandongrotesque-bold-webfont.woff2') format('woff2'),
    url('../fonts/brandongrotesque-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// @font-face {
//   font-family: 'Playfair';
//   src: url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
//     url('../fonts/playfairdisplay-regular-webfont.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
// }
