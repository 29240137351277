.slick-wrapper {
    position: relative;
    width: 450px;
    height: auto;
    margin: 50px auto;
    background-color: transparent;
    @media (max-width: 530px) {
        width: 350px;
    }
    @media (max-width: 400px) {
        width: 250px;
    }
    .slide-item {
        color: #000;
        text-align: center;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &__image {
            position: relative;
            width: 350px;
            height: 350px;
            margin: auto;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @media (max-width: 530px) {
                width: 250px;
                height: 250px;
            }
            @media (max-width: 400px) {
                width: 200px;
                height: 200px;
            }
            &__magnifier {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                background-color: rgba(18, 129, 147, 0.5);
                &:before, &:after {
                    position: absolute;
                    left: 25px;
                    top: 10px;
                    content: ' ';
                    height: 30px;
                    width: 2px;
                    background-color: #fff;
                }
                &:before {
                    transform: rotate(90deg);
                }
            }
        }
        &__text {
            color: #fff;
            margin-top: 20px;
            font: 200 normal 16px/26px Roboto;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0 2px;
            &.slick-active button {
                background-color: #fff;
            }
        }
        button {
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: none;
            background-color: rgba(255,255,255,0.2);
        }
    }
    .controlbutton {
        position: absolute;
        top: 150px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s;
        z-index: 100;
        @media (max-width: 530px) {
            top: 100px;
        }
        @media (max-width: 400px) {
            top: 75px;
        }
        &--left {
            left: 0;
            transform: rotate(135deg);
            @media (max-width: 400px) {
                left: -40px;
            }
            &__inner {
                margin-left: 3px;
            }
        }
        &--right {
            right: 0;
            transform: rotate(-45deg);
            @media (max-width: 400px) {
                right: -40px;
            }
            &_inner {
                margin-right: 3px;
            }
        }
        &__inner {
            width: 20px;
            height: 20px;
            border-right: 3px solid rgba(255,255,255,0.2);
            border-bottom: 3px solid rgba(255,255,255,0.2);
            transition: all 0.5s;
        }
        &:hover .controlbutton__inner {
            border-color: #fff;
            transition: all 0.5s;
        }
    }
    .slick-wrapper__close {
        position: fixed;
        right: 0;
        top: 0;
        width: 80px;
        height: 80px;
        background-color: #fff;
        opacity: 0.8;
        transition: all 0.5s;
        display: none;
        z-index: 200;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
        &:before, &:after {
            position: absolute;
            left: 40px;
            top: 10px;
            content: ' ';
            height: 60px;
            width: 2px;
            background-color: #333;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    &.fullscreen {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin: 0;
        background-color: rgb(18, 129, 160);
        z-index: 100;
        .slick-wrapper__close {
            display: block;
        }
        .slide-item__image {
            width: 100vw;
            height: 70vh;
            margin-top: 40px;
            background-size: contain;
            @media (max-width: 850px) {
                height: 60vh;
            }
            @media (max-width: 650px) {
                width: 80vw;
                height: 50vh;
                margin-top: 20px;
            }
            &__magnifier {
                display: none;
            }    
        }
        .slide-item__text {
            width: 50vw;
            margin: auto;
            margin-top: 20px;
            @media (max-width: 1000px) {
                width: 80%;
            }
            @media (max-width: 650px) {
                margin-top: 5px;
                font: 200 normal 14px/26px Roboto;
            }
        }
        .controlbutton {
            top: 30%;
            width: 150px;
            height: 150px;
            &--left {
                @media (max-width: 650px) {
                    left: 8px;
                }
            }
            &--right {
                @media (max-width: 650px) {
                    right: 8px;
                }
            }
            @media (max-width: 650px) {
                top: 25%;
                width: 30px;
                height: 30px;
            }
            &__inner {
                width: 80px;
                height: 80px;
                @media (max-width: 650px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .slick-dots {
            display: none;
        }
    }
}