.accordion {
    border-bottom: 2px solid #fff;
    &__headline {
        padding: 50px 0;
        padding-left: 5px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-heading {
            font: 400 normal 26px 'Playfair Display';
            line-height: 2em;
            margin-left: 20px;
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 2px;
                left: 0;
                bottom: -15px;
                background-color: #fff;
                transition: all 0.5s;
            }
            &.active::after {
                width: 150px;
                transition: all 0.5s;
            }
        }
        &-button {
            cursor: pointer;
            background-color: transparent;
            color: #fff;
            border: 2px solid #fff;
            width: 45px;
            height: 45px;
            display: flex;
            font: 500 normal 16px/16px Roboto;
            justify-content: center;
            align-items: center;
            margin: 20px;
            transition: all 0.5s;
            &:focus {
                outline: 0;
            }
            &:hover {
                background-color: #fff;
                color: rgb(18, 129, 140);
            }
            &.active > div {
                transform: rotate(45deg);
                transition: all 0.5s;
            }
        }
    }
    &__content {
        font: 300 normal 16px/26px Roboto;
        padding: 0 0 20px 5px;
        margin: 30px 0 0 20px;
        display: none;
        &-nobutton {
            font: 300 normal 16px/26px Roboto;
            padding: 0 0 40px 5px;
            margin: 0 0 50px 20px;;
            display: block;
        }
        &.visible {
            display: block;
        }
    }
}
@media only screen and (max-width: 600px) {
    .accordion__headline {
        padding-left: 0;
        &-heading {
            margin-left: 0;
            max-width: 210px;
            font-size: 20px;
        }
        &-button {
            margin: 0;
        }
    }
}