@charset "UTF-8";
@font-face {
  font-family: 'Brandon';
  src: url("../fonts/brandongrotesque-bold-webfont.woff2") format("woff2"), url("../fonts/brandongrotesque-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

body {
  height: 100vh;
  width: 100vw;
  background-color: #12818c;
  color: #ffffff;
  font: 200 normal 20px/24px 'Roboto';
  overflow: visible; }
  body.noscroll {
    overflow: hidden; }

#page__wrapper {
  width: 1090px;
  margin: 0 auto;
  position: relative;
  height: 100px;
  padding: 290px 0 0 200px; }
  @media (max-width: 1279px) {
    #page__wrapper {
      padding: 290px 0 0 0;
      width: 906px; } }
  @media (max-width: 767px) {
    #page__wrapper {
      padding: 160px 0 0 0; } }
  @media (max-width: 1037px) {
    #page__wrapper {
      width: calc(100% - 80px); } }

aside {
  position: fixed;
  top: 0;
  left: calc(50% - 545px);
  z-index: 1;
  width: 115px; }
  @media (max-width: 1279px) {
    aside {
      display: block;
      left: calc(50% - 453px);
      z-index: 10;
      position: absolute; } }
  @media (max-width: 1037px) {
    aside {
      left: 40px; } }

a,
a:hover {
  color: #ffffff;
  text-decoration: none; }

.navigation__main {
  margin-top: 180px; }
  .navigation__main-list {
    margin: 0;
    padding: 0; }
    @media (max-width: 1279px) {
      .navigation__main-list {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); } }
  .navigation__main-item {
    list-style: none; }
  .navigation__main-link {
    min-width: 130px;
    font: 700 normal 16px/42px 'Josefin Sans';
    display: block;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 1.2px; }
    @media (max-width: 1279px) {
      .navigation__main-link {
        font: 700 normal 25px/60px 'Josefin Sans'; } }
    .navigation__main-link span {
      position: relative;
      pointer-events: none; }
      .navigation__main-link span::after {
        content: '';
        width: 100%;
        position: absolute;
        top: 100%;
        height: 2px;
        background: #fff;
        left: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: 0.3s;
        transform-origin: 0 0; }
    .navigation__main-link:hover span::after,
    .navigation__main-link--active span::after {
      transform: scaleX(1);
      opacity: 1; }
  @media (max-width: 1279px) {
    .navigation__main {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      margin: 0;
      text-align: center;
      opacity: 0;
      z-index: 20; } }
  .navigation__main .to_contact {
    font: 700 normal 16px/16px 'Josefin Sans';
    text-transform: uppercase;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    display: none; }
    @media (max-width: 1279px) {
      .navigation__main .to_contact {
        display: inline-block; } }

.top-navigation {
  position: absolute;
  top: 40px;
  right: 0;
  text-align: right;
  font: 700 normal 16px/16px 'Josefin Sans';
  font-family: 'Josefin Sans', sans-serif;
  display: block;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1.2px; }
  .top-navigation__list {
    margin: 0;
    padding: 0; }
  .top-navigation__item {
    list-style: none; }
  .top-navigation__link {
    position: relative; }
    .top-navigation__link::after {
      content: '';
      width: 100%;
      position: absolute;
      top: 100%;
      height: 2px;
      background: #fff;
      left: 0;
      transform: scaleX(0);
      opacity: 0;
      transition: 0.3s;
      transform-origin: 0 0; }
    .top-navigation__link:hover::after, .top-navigation__link--active::after {
      transform: scaleX(1);
      opacity: 1; }
  @media (max-width: 1279px) {
    .top-navigation {
      display: none; } }

.logo {
  display: block;
  margin-top: 40px; }
  .logo img {
    height: 70px; }

h1 {
  font: 400 normal 48px/53px 'Playfair Display';
  padding: 0 156px 0 92px; }
  @media (max-width: 1037px) {
    h1 {
      padding: 0; } }
  @media (max-width: 767px) {
    h1 {
      font: 400 normal 36px/46px 'Playfair Display'; } }
  @media (max-width: 365px) {
    h1 {
      font: 400 normal 28px/38px 'Playfair Display'; } }

.start__text {
  padding: 20px 156px 0 92px; }
  @media (max-width: 1037px) {
    .start__text {
      padding: 10px 0 0 0; } }
  @media (max-width: 767px) {
    .start__text {
      display: none; } }

.start__content {
  background: #ffffff;
  margin: 70px 0 0;
  color: #12818c;
  position: relative; }
  @media (max-width: 767px) {
    .start__content {
      width: 100vw;
      margin: 60px 0 0 -40px;
      padding: 0px 40px; } }

.start__image {
  height: 564px;
  width: 830px;
  display: block;
  margin: -20px 0 0 -20px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: inline-block; }
  @media (max-width: 1037px) {
    .start__image {
      width: calc(100vw - 220px);
      height: calc((100vw - 220px) * 0.51); } }
  @media (max-width: 767px) {
    .start__image {
      margin-left: -20px;
      width: calc(100vw - 40px);
      height: calc((100vw - 40px) * 0.64); } }
  .start__image-cover {
    background-color: #12818c;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    overflow: hidden;
    opacity: 1;
    transform: translateX(-100%); }
  .start__image-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    transform: translateX(96%); }

.start__fond {
  display: block;
  width: 830px;
  height: 565px;
  background-color: #12818c;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px; }
  @media (max-width: 1037px) {
    .start__fond {
      width: calc(100vw - 219px);
      height: calc((100vw - 220px) * 0.51); } }
  @media (max-width: 767px) {
    .start__fond {
      margin-left: 41px;
      width: calc(100vw - 40px);
      height: calc((100vw - 40px) * 0.64); } }

.start__body {
  padding: 96px 92px 96px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .start__body {
      margin-top: 70px;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 50px 40px 90px;
      display: block; } }
  .start__body-title {
    font: 400 normal 34px/45px 'Playfair Display';
    flex: 0 0 calc(40% - 20px); }
    @media (max-width: 365px) {
      .start__body-title {
        font: 400 normal 28px/38px 'Playfair Display'; } }
  .start__body-text {
    flex: 0 0 calc(60% - 20px);
    font: 300 normal 20px/24px 'Roboto'; }
    .start__body-text b {
      font-weight: 600; }
    @media (max-width: 365px) {
      .start__body-text {
        font: 300 normal 18px/20px 'Roboto'; } }
    @media (max-width: 1037px) {
      .start__body-text {
        flex: 0 0 calc(40% - 20px); } }

.uber-uns {
  background-color: #fff;
  color: #12818c;
  margin: 20px 0 0;
  padding: 90px 92px 90px;
  position: relative; }
  @media (max-width: 767px) {
    .uber-uns {
      width: 100vw;
      margin-left: -40px;
      padding: 50px 40px 90px; } }
  .uber-uns__title {
    font: 400 normal 34px/45px 'Playfair Display';
    position: relative; }
    @media (max-width: 365px) {
      .uber-uns__title {
        font: 400 normal 28px/38px 'Playfair Display'; } }
    .uber-uns__title::after {
      content: '';
      display: block;
      background-color: #12818c;
      opacity: 1;
      position: absolute;
      width: 52px;
      height: 2px;
      left: 0;
      bottom: -25px; }
  .uber-uns__text {
    padding: 26px 0 0 276px;
    font: 300 normal 20px/24px 'Roboto'; }
    @media (max-width: 365px) {
      .uber-uns__text {
        font: 300 normal 18px/22px 'Roboto'; } }
    @media (max-width: 767px) {
      .uber-uns__text {
        padding: 60px 0 0 0; } }
  .uber-uns__person {
    display: flex;
    flex-wrap: nowrap;
    margin: 100px 0 0 -22px; }
    @media (max-width: 767px) {
      .uber-uns__person {
        margin: 50px 0 0 0; } }
  .uber-uns__card {
    flex: 0 0 482px; }
    @media (max-width: 1037px) {
      .uber-uns__card {
        flex: 0 0 362px; } }
    @media (max-width: 767px) {
      .uber-uns__card {
        display: block;
        width: 100%;
        flex: none; } }
    .uber-uns__card-item {
      border: 2px solid #12818c;
      padding: 20px;
      cursor: pointer;
      position: relative;
      margin: -2px 0 0;
      width: 372px; }
      @media (max-width: 1037px) {
        .uber-uns__card-item {
          width: 322px; } }
      @media (max-width: 767px) {
        .uber-uns__card-item {
          width: 100%; } }
      @media (max-width: 365px) {
        .uber-uns__card-item {
          padding: 10px; } }
      .uber-uns__card-item::after {
        position: absolute;
        pointer-events: none;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 5px solid #fff;
        content: ''; }
      .uber-uns__card-item--current, .uber-uns__card-item:hover {
        border: 2px solid #12818c;
        z-index: 2; }
        .uber-uns__card-item--current::after, .uber-uns__card-item:hover::after {
          content: none; }
    .uber-uns__card-photo {
      height: 74px;
      width: 74px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      background-size: contain;
      background-repeat: no-repeat; }
    .uber-uns__card-data {
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      padding: 0 0 0 20px; }
      @media (max-width: 365px) {
        .uber-uns__card-data {
          padding: 0 0 0 10px; } }
    .uber-uns__card-title {
      font: 500 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
      padding-bottom: 4px; }
    .uber-uns__card-subtitle {
      font: 300 normal 16px/19px 'Roboto'; }
  .uber-uns__photo {
    flex: 0 0 420px;
    position: relative;
    overflow: hidden; }
    @media (max-width: 1037px) {
      .uber-uns__photo {
        flex: 0 0 calc(100vw - 80px - 92px - 320px); } }
    @media (max-width: 767px) {
      .uber-uns__photo {
        display: none; } }
    .uber-uns__photo-cover {
      position: relative;
      height: 270px;
      width: 100%;
      margin-top: -2px; }
      @media (max-width: 1037px) {
        .uber-uns__photo-cover {
          height: calc((100vw - 80px - 92px - 320px) * 0.64); } }
    .uber-uns__photo-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateX(-100%); }
    .uber-uns__photo-fond {
      background-color: #12818c;
      position: absolute;
      content: '';
      width: calc(100% - 20px);
      height: 20px;
      top: 100%;
      left: 20px;
      opacity: 1; }
    .uber-uns__photo-data {
      padding: 60px 0 5px; }
    .uber-uns__photo-title {
      font: 400 normal 24px/32px 'Playfair Display'; }
    .uber-uns__photo-subtitle {
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px; }
    .uber-uns__photo-phone, .uber-uns__photo-email, .uber-uns__photo-xing, .uber-uns__photo-linkedin {
      margin-top: 5px;
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px; }
      .uber-uns__photo-phone a, .uber-uns__photo-email a, .uber-uns__photo-xing a, .uber-uns__photo-linkedin a {
        color: #12818c;
        position: relative; }
        .uber-uns__photo-phone a::after, .uber-uns__photo-email a::after, .uber-uns__photo-xing a::after, .uber-uns__photo-linkedin a::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #12818c;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0; }
        .uber-uns__photo-phone a:hover, .uber-uns__photo-email a:hover, .uber-uns__photo-xing a:hover, .uber-uns__photo-linkedin a:hover {
          color: #12818c; }
        .uber-uns__photo-phone a:hover::after, .uber-uns__photo-email a:hover::after, .uber-uns__photo-xing a:hover::after, .uber-uns__photo-linkedin a:hover::after {
          transform: scaleX(1);
          opacity: 1; }
    .uber-uns__photo-xing, .uber-uns__photo-linkedin {
      display: inline-block;
      margin: 20px 10px 0 0;
      font-size: 25px; }
  .uber-uns__description {
    font: 300 normal 16px/26px 'Roboto';
    padding: 50px 0 100px 0; }
    @media (max-width: 767px) {
      .uber-uns__description {
        padding: 50px 0 50px 0; } }
    @media (max-width: 365px) {
      .uber-uns__description {
        font: 300 normal 16px/24px 'Roboto';
        padding: 25px 0 50px 0; } }
  .uber-uns__to-contact {
    display: flex;
    padding-top: 50px; }
    @media (max-width: 767px) {
      .uber-uns__to-contact {
        display: block; } }
    .uber-uns__to-contact h3 {
      font: 400 normal 24px/32px 'Playfair Display'; }
      @media (max-width: 767px) {
        .uber-uns__to-contact h3 {
          font: 400 normal 22px/30px 'Playfair Display'; } }
    .uber-uns__to-contact > div:last-child {
      flex: 0 0 200px;
      text-align: right; }
      @media (max-width: 767px) {
        .uber-uns__to-contact > div:last-child {
          text-align: left; } }
  .uber-uns__contactMobile {
    display: none;
    padding-bottom: 50px; }
    @media (max-width: 767px) {
      .uber-uns__contactMobile {
        display: block; } }
    .uber-uns__contactMobile-title {
      font: 400 normal 24px/32px 'Playfair Display'; }
    .uber-uns__contactMobile-subtitle {
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px; }
    .uber-uns__contactMobile-phone, .uber-uns__contactMobile-email, .uber-uns__contactMobile-xing, .uber-uns__contactMobile-linkedin {
      margin-top: 5px;
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px; }
      .uber-uns__contactMobile-phone a, .uber-uns__contactMobile-email a, .uber-uns__contactMobile-xing a, .uber-uns__contactMobile-linkedin a {
        color: #12818c;
        position: relative; }
        .uber-uns__contactMobile-phone a::after, .uber-uns__contactMobile-email a::after, .uber-uns__contactMobile-xing a::after, .uber-uns__contactMobile-linkedin a::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #12818c;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0; }
        .uber-uns__contactMobile-phone a:hover, .uber-uns__contactMobile-email a:hover, .uber-uns__contactMobile-xing a:hover, .uber-uns__contactMobile-linkedin a:hover {
          color: #12818c; }
        .uber-uns__contactMobile-phone a:hover::after, .uber-uns__contactMobile-email a:hover::after, .uber-uns__contactMobile-xing a:hover::after, .uber-uns__contactMobile-linkedin a:hover::after {
          transform: scaleX(1);
          opacity: 1; }
    .uber-uns__contactMobile-xing, .uber-uns__contactMobile-linkedin {
      display: inline-block;
      margin: 20px 10px 0 0;
      font-size: 25px; }

hr {
  border-top: 2px solid rgba(18, 129, 140, 0.2); }

.button, .leistugen__item-link, .leistugen__item-link--accordion, .contact__button, .open-menu, .close-menu {
  color: #12818c;
  border: 2px solid #12818c;
  background-color: transparent;
  margin-top: 5px;
  font: 500 normal 16px/16px 'Roboto';
  padding: 10px 15px 11px;
  display: inline-block;
  white-space: nowrap; }
  .button:hover, .leistugen__item-link:hover, .leistugen__item-link--accordion:hover, .contact__button:hover, .open-menu:hover, .close-menu:hover {
    background-color: #12818c;
    color: #ffffff;
    transition: 0.5s; }

.footer {
  display: flex;
  justify-content: space-between;
  padding: 62px 0 98px 90px;
  font: 500 normal 16px/19px 'Roboto'; }
  @media (max-width: 1037px) {
    .footer {
      display: block;
      padding: 0; } }
  .footer__contact-email, .footer__contact-phone {
    margin-top: 38px;
    display: block;
    font: 500 normal 16px/19px 'Roboto';
    color: #ffffff; }
    @media (max-width: 1037px) {
      .footer__contact-email, .footer__contact-phone {
        margin-top: 20px; } }
    .footer__contact-email span, .footer__contact-phone span {
      position: relative;
      pointer-events: none; }
      .footer__contact-email span::after, .footer__contact-phone span::after {
        content: '';
        width: 100%;
        position: absolute;
        top: 100%;
        height: 2px;
        background: #fff;
        left: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: 0.3s;
        transform-origin: 0 0; }
    .footer__contact-email:hover span::after, .footer__contact-phone:hover span::after {
      transform: scaleX(1);
      opacity: 1; }
  @media (max-width: 1037px) {
    .footer__contact {
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      padding: 10px 0 30px 0; } }
  @media (max-width: 1037px) {
    .footer__address {
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      padding: 10px 0 30px 0; } }
  .footer__address-street, .footer__address-city {
    margin-top: 38px; }
    @media (max-width: 1037px) {
      .footer__address-street, .footer__address-city {
        margin-top: 20px; } }
  .footer__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 1037px) {
      .footer__links {
        padding: 20px 0 30px 0; } }
    .footer__links a {
      display: inline-block;
      font: 500 normal 16px/19px 'Roboto';
      color: #ffffff; }
      .footer__links a span {
        position: relative;
        pointer-events: none; }
        .footer__links a span::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #fff;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0; }
      .footer__links a:hover span::after {
        transform: scaleX(1);
        opacity: 1; }

.leistugen {
  padding: 100px 0 90px 90px; }
  @media (max-width: 1037px) {
    .leistugen {
      padding-left: 0; } }
  .leistugen-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    @media (max-width: 767px) {
      .leistugen-list {
        display: none; } }
  .leistugen__item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .leistugen__item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
    @media (max-width: 767px) {
      .leistugen__item {
        display: block;
        width: 100%;
        flex: none;
        padding-bottom: 25px; } }
    .leistugen__item-title {
      border-top: 2px solid #ffffff;
      padding: 50px 0 4px;
      font: 400 normal 30px/36px 'Playfair Display';
      min-height: 175px; }
      @media (max-width: 1037px) {
        .leistugen__item-title {
          font-size: 26px; } }
      @media (max-width: 767px) {
        .leistugen__item-title {
          min-height: auto; } }
    .leistugen__item-subtitle {
      font: 500 normal 16px/23px 'Roboto';
      min-height: 95px; }
      .leistugen__item-subtitle span {
        font: 500 normal 16px/32px 'Roboto'; }
    .leistugen__item-link, .leistugen__item-link--accordion {
      color: white;
      border-color: white;
      margin-top: 30px; }
      .leistugen__item-link:hover, .leistugen__item-link--accordion:hover {
        background-color: #ffffff;
        color: #12818c;
        opacity: 1 !important; }
      .leistugen__item-link.active span, .leistugen__item-link--accordion.active span {
        display: block;
        transform: rotate(45deg);
        transition: 0.2s; }
  .leistugen__container .leistugen__item {
    display: none; }
    @media (max-width: 767px) {
      .leistugen__container .leistugen__item {
        display: block; } }
  .leistugen__content {
    display: none;
    padding-top: 75px; }
    .leistugen__content.show {
      display: block;
      opacity: 1;
      transition: 1s; }
    .leistugen__content-title {
      font: 400 normal 34px/45px 'Playfair Display';
      position: relative;
      width: 40%; }
      @media (max-width: 1037px) {
        .leistugen__content-title {
          font-size: 26px; } }
      .leistugen__content-title::after {
        content: '';
        display: block;
        background-color: #fff;
        opacity: 1;
        position: absolute;
        width: 52px;
        height: 2px;
        left: 0;
        bottom: -25px; }
    .leistugen__content-text {
      padding-top: 50px;
      font: 200 normal 16px/26px 'Roboto'; }
      .leistugen__content-text--underline {
        text-decoration: underline; }
  .leistugen hr {
    border-top: 2px solid rgba(255, 255, 255, 0.2); }
  .leistugen__to-contact {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px; }
    @media (max-width: 767px) {
      .leistugen__to-contact {
        display: block; } }
    .leistugen__to-contact h3 {
      font: 400 normal 24px/32px 'Playfair Display'; }
      @media (max-width: 767px) {
        .leistugen__to-contact h3 {
          font: 400 normal 22px/30px 'Playfair Display'; } }
    .leistugen__to-contact > div:last-child {
      flex: 0 0 200px;
      text-align: right; }
      @media (max-width: 767px) {
        .leistugen__to-contact > div:last-child {
          text-align: left; } }
    .leistugen__to-contact .button, .leistugen__to-contact .leistugen__item-link, .leistugen__to-contact .leistugen__item-link--accordion, .leistugen__to-contact .contact__button, .leistugen__to-contact .open-menu, .leistugen__to-contact .close-menu {
      border-color: white;
      color: white; }
      .leistugen__to-contact .button:hover, .leistugen__to-contact .leistugen__item-link:hover, .leistugen__to-contact .leistugen__item-link--accordion:hover, .leistugen__to-contact .contact__button:hover, .leistugen__to-contact .open-menu:hover, .leistugen__to-contact .close-menu:hover {
        background-color: #fff;
        color: #12818c; }
  .leistugen__whitepaper {
    background-color: #fff;
    color: #12818c;
    padding: 20px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .leistugen__whitepaper .accordion {
        border-bottom: none; } }
    .leistugen__whitepaper .accordion__headline  {
      padding: 40px 0 40px 0; }
    .leistugen__whitepaper .accordion__headline-button {
      margin: 0;
      color: #12818c;
      border-color: #12818c; }
    .leistugen__whitepaper .accordion__headline-heading {
      margin-left: 0;
      max-width: none; }
      @media (max-width: 400px) {
        .leistugen__whitepaper .accordion__headline-heading {
          max-width: 200px; } }
      .leistugen__whitepaper .accordion__headline-heading::after {
        content: none; }
    .leistugen__whitepaper .accordion__content {
      padding-left: 0;
      margin-left: 0;
      position: relative; }
      .leistugen__whitepaper .accordion__content p {
        margin-bottom: 70px; }
      .leistugen__whitepaper .accordion__content .errormessage {
        color: red;
        margin-bottom: 20px;
        display: none; }
        .leistugen__whitepaper .accordion__content .errormessage.active {
          display: block; }
      .leistugen__whitepaper .accordion__content .step-1 {
        position: relative;
        display: block; }
        .leistugen__whitepaper .accordion__content .step-1 form {
          width: 400px; }
          @media (max-width: 768px) {
            .leistugen__whitepaper .accordion__content .step-1 form {
              width: 100%; } }
        .leistugen__whitepaper .accordion__content .step-1 input {
          display: block;
          margin: 0 0 20px 0;
          padding: 5px 5px 5px 10px;
          font: 300 normal 24px/30px "Roboto";
          width: 100%;
          border: 2px solid #12818c;
          background-color: transparent;
          color: #12818c;
          outline: none; }
          .leistugen__whitepaper .accordion__content .step-1 input::placeholder {
            color: #12818c; }
          .leistugen__whitepaper .accordion__content .step-1 input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #12818c; }
          .leistugen__whitepaper .accordion__content .step-1 input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #12818c; }
          .leistugen__whitepaper .accordion__content .step-1 input.error {
            border-color: red; }
            .leistugen__whitepaper .accordion__content .step-1 input.error::placeholder {
              color: red; }
            .leistugen__whitepaper .accordion__content .step-1 input.error:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: red; }
            .leistugen__whitepaper .accordion__content .step-1 input.error::-ms-input-placeholder {
              /* Microsoft Edge */
              color: red; }
        .leistugen__whitepaper .accordion__content .step-1 .datenschutz {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          .leistugen__whitepaper .accordion__content .step-1 .datenschutz a {
            color: #12818c;
            text-decoration: underline; }
          .leistugen__whitepaper .accordion__content .step-1 .datenschutz input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0; }
          .leistugen__whitepaper .accordion__content .step-1 .datenschutz .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 2px solid #12818c;
            background-color: transparent; }
            .leistugen__whitepaper .accordion__content .step-1 .datenschutz .checkmark::after  {
              content: "";
              position: absolute;
              display: none;
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid #12818c;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
          .leistugen__whitepaper .accordion__content .step-1 .datenschutz input:checked ~ .checkmark:after {
            display: block; }
        .leistugen__whitepaper .accordion__content .step-1 button.datenschutz-button {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 15px;
          font: 300 normal 24px/30px "Roboto";
          background-color: transparent;
          color: #12818c;
          border: 2px solid #12818c;
          outline: none; }
          @media (max-width: 768px) {
            .leistugen__whitepaper .accordion__content .step-1 button.datenschutz-button {
              position: static;
              margin-left: 35px; } }
        .leistugen__whitepaper .accordion__content .step-1 button.datenschutz-button:disabled,
        .leistugen__whitepaper .accordion__content .step-1 button.datenschutz-button[disabled] {
          color: rgba(18, 129, 140, 0.2);
          border-color: rgba(18, 129, 140, 0.2); }
      .leistugen__whitepaper .accordion__content .step-2 {
        display: none;
        scroll-padding-top: 100px; }
        .leistugen__whitepaper .accordion__content .step-2 .progressbar  {
          position: relative;
          width: 100%;
          height: 25px;
          background-color: transparent;
          border: 2px solid #12818c;
          border-radius: 10px;
          overflow: hidden; }
        .leistugen__whitepaper .accordion__content .step-2 .progressbar-inner {
          position: absolute;
          width: 0%;
          height: 25px;
          background-color: #12818c; }
        .leistugen__whitepaper .accordion__content .step-2 .download-steps {
          display: flex;
          justify-content: space-between;
          margin: 50px 0 0px 0; }
          .leistugen__whitepaper .accordion__content .step-2 .download-steps > div {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center; }
            .leistugen__whitepaper .accordion__content .step-2 .download-steps > div .icon {
              margin-bottom: 30px; }
          @media (max-width: 610px) {
            .leistugen__whitepaper .accordion__content .step-2 .download-steps {
              flex-direction: column;
              align-items: center; }
              .leistugen__whitepaper .accordion__content .step-2 .download-steps > div {
                width: 70%;
                margin-bottom: 40px; }
                .leistugen__whitepaper .accordion__content .step-2 .download-steps > div .icon {
                  margin-bottom: 10px; } }

.whitepaper-title {
  font: 400 normal 30px/60px 'Playfair Display'; }

.leistugen__whitepaper .accordion__content p  {
  margin-bottom: 20px; }

.kompetenzen {
  margin-bottom: 50px;
  padding: 50px 0 90px 90px; }
  @media (max-width: 1037px) {
    .kompetenzen {
      padding-left: 0; } }
  .kompetenzen--whitebg {
    margin-bottom: 0;
    background-color: #fff;
    color: #12818c; }
    .kompetenzen--whitebg .kompetenzen__title {
      position: relative;
      font: 400 normal 34px/45px 'Playfair Display'; }
      .kompetenzen--whitebg .kompetenzen__title::after {
        content: '';
        display: block;
        background-color: #12818c;
        opacity: 1;
        position: absolute;
        width: 52px;
        height: 2px;
        left: 0;
        bottom: -25px; }
    .kompetenzen--whitebg .kompetenzen__text {
      padding: 40px 130px 0 0;
      font: 300 normal 20px/24px Roboto; }
    @media (max-width: 1037px) {
      .kompetenzen--whitebg {
        padding-left: 30px; } }

.contact {
  display: none;
  opacity: 0;
  color: #12818c;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
  background-color: #fff; }
  .contact__wrapper {
    width: 1090px;
    margin: 0 auto;
    position: relative; }
    @media (max-width: 1037px) {
      .contact__wrapper {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px; } }
  .contact__logo {
    position: absolute;
    top: 40px;
    left: 0; }
    @media (max-width: 1037px) {
      .contact__logo {
        left: 40px; } }
    .contact__logo img {
      height: 70px; }
  .contact__button {
    position: absolute;
    right: 0;
    top: 53px; }
    .contact__button span {
      display: block;
      transform: rotate(45deg); }
    @media (max-width: 1037px) {
      .contact__button {
        right: 40px; } }
  .contact__content {
    padding-top: 48vh;
    display: flex; }
    @media (max-width: 1037px) {
      .contact__content {
        padding-top: 30vh;
        display: block; } }
  .contact__title {
    font: 400 normal 48px/53px 'Playfair Display';
    position: relative;
    margin-top: -70px;
    margin-right: 90px; }
    @media (max-width: 365px) {
      .contact__title {
        margin-top: -50px; } }
    .contact__title::after {
      content: '';
      position: absolute;
      width: 52px;
      height: 2px;
      background-color: #12818c;
      left: 0;
      bottom: 125px; }
      @media (max-width: 1037px) {
        .contact__title::after {
          bottom: -20px; } }
  .contact__info {
    margin-right: 90px; }
    @media (max-width: 365px) {
      .contact__info {
        margin-right: 0px; } }
    @media (max-width: 1037px) {
      .contact__info {
        margin-top: 50px; } }
    .contact__info-title {
      font: 400 normal 24px/32px 'Playfair Display'; }
    .contact__info-email, .contact__info-phone {
      display: block;
      font: 500 normal 16px/42px 'Roboto';
      margin: 13px 0 0;
      color: #12818c; }
      .contact__info-email span, .contact__info-phone span {
        position: relative;
        pointer-events: none; }
        .contact__info-email span::after, .contact__info-phone span::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #12818c;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0; }
      .contact__info-email:hover, .contact__info-phone:hover {
        color: #12818c; }
      .contact__info-email:hover span::after, .contact__info-phone:hover span::after {
        transform: scaleX(1);
        opacity: 1; }
  .contact__address {
    margin-right: 90px; }
    @media (max-width: 1037px) {
      .contact__address {
        margin-top: 30px; } }
    .contact__address-title {
      font: 400 normal 24px/32px 'Playfair Display'; }
    .contact__address-street, .contact__address-city {
      display: block;
      font: 500 normal 16px/42px 'Roboto';
      margin: 13px 0 0; }

.open-menu {
  color: white;
  border-color: white;
  transition: 0.3s;
  position: absolute;
  right: calc(50% - 545px);
  top: 53px;
  z-index: 1;
  display: none; }
  .open-menu:hover {
    background-color: #ffffff;
    color: #12818c; }
  @media (min-width: 1280px) {
    .open-menu {
      display: none !important; } }
  @media (max-width: 1279px) {
    .open-menu {
      display: block;
      right: calc(50% - 453px); } }
  @media (max-width: 1037px) {
    .open-menu {
      right: 40px; } }

.close-menu {
  color: white;
  border-color: white;
  transition: 0.3s;
  display: none;
  position: absolute;
  right: calc(50% - 545px);
  top: 53px;
  z-index: 1; }
  .close-menu:hover {
    background-color: #ffffff;
    color: #12818c; }
  .close-menu span {
    display: block;
    transform: rotate(45deg); }
  @media (min-width: 1280px) {
    .close-menu {
      display: none !important; } }
  @media (max-width: 1279px) {
    .close-menu {
      display: block;
      right: calc(50% - 453px); } }
  @media (max-width: 1037px) {
    .close-menu {
      right: 40px; } }

.impressum__box {
  background-color: #fff;
  color: #12818c;
  margin: 20px 0 0;
  padding: 90px 92px 90px;
  position: relative;
  display: flex;
  flex-wrap: nowrap; }
  @media (max-width: 767px) {
    .impressum__box {
      width: 100vw;
      margin-left: -40px;
      padding: 50px 40px 90px; } }

.impressum__contact {
  flex: 0 0 482px; }
  @media (max-width: 1037px) {
    .impressum__contact {
      flex: 0 0 342px; } }
  @media (max-width: 767px) {
    .impressum__contact {
      display: block;
      width: 100%;
      flex: none; } }

.impressum__title {
  font: 400 normal 34px/45px 'Playfair Display';
  position: relative; }
  @media (max-width: 365px) {
    .impressum__title {
      font: 400 normal 28px/38px 'Playfair Display'; } }
  .impressum__title::after {
    content: '';
    display: block;
    background-color: #12818c;
    opacity: 1;
    position: absolute;
    width: 52px;
    height: 2px;
    left: 0;
    bottom: -25px; }

.impressum__text {
  padding: 50px 0 0 0;
  font: 300 normal 20px/24px 'Roboto'; }
  .impressum__text a {
    color: #12818c;
    position: relative; }
    .impressum__text a::after {
      content: '';
      position: absolute;
      width: 100%;
      top: 100%;
      height: 2px;
      background: #12818c;
      left: 0;
      transform: scaleX(0);
      opacity: 0;
      transition: 0.3s;
      transform-origin: 0 0; }
    .impressum__text a:hover {
      color: #12818c; }
    .impressum__text a:hover::after {
      transform: scaleX(1);
      opacity: 1; }
  @media (max-width: 365px) {
    .impressum__text {
      font: 200 normal 18px/22px 'Roboto'; } }
  @media (max-width: 767px) {
    .impressum__text {
      padding: 60px 0 0 0; } }

.impressum__photo {
  flex: 0 0 420px;
  position: relative; }
  @media (max-width: 1037px) {
    .impressum__photo {
      flex: 0 0 calc(100vw - 80px - 92px - 320px); } }
  @media (max-width: 767px) {
    .impressum__photo {
      display: none; } }
  .impressum__photo-cover {
    position: relative;
    height: 270px;
    width: 100%;
    margin-top: -2px; }
    @media (max-width: 1037px) {
      .impressum__photo-cover {
        height: calc((100vw - 80px - 92px - 320px) * 0.64); } }
  .impressum__photo-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat; }
  .impressum__photo-fond {
    background-color: #12818c;
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: 20px;
    top: 100%;
    left: 20px;
    opacity: 1; }
  .impressum__photo-data {
    padding: 60px 0 5px; }
  .impressum__photo-title {
    font: 400 normal 24px/32px 'Playfair Display'; }
  .impressum__photo-subtitle {
    font: 200 normal 16px/19px 'Roboto';
    letter-spacing: 0.5px; }

.impressum__content {
  color: #fff;
  font-family: 'Roboto';
  padding: 90px 92px 90px;
  position: relative; }
  @media (max-width: 767px) {
    .impressum__content {
      width: 100vw;
      margin-left: -40px;
      padding: 50px 40px 90px; } }
  .impressum__content h2 {
    font: 400 normal 34px/45px 'Playfair Display';
    position: relative;
    margin-bottom: 50px; }
    @media (max-width: 1037px) {
      .impressum__content h2 {
        font: 400 normal 28px/38px 'Playfair Display'; } }
    @media (max-width: 365px) {
      .impressum__content h2 {
        font: 400 normal 26px/38px 'Playfair Display'; } }
    .impressum__content h2::after {
      content: '';
      display: block;
      background-color: #fff;
      opacity: 1;
      position: absolute;
      width: 52px;
      height: 2px;
      left: 0;
      bottom: -25px; }
  .impressum__content h4 {
    font-weight: bold;
    font-style: italic; }

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 67px;
  background-color: #fff;
  z-index: 9;
  transition: 0.2s;
  opacity: 0;
  transform: translateY(-100%); }
  .sticky__wrapper {
    width: 1090px;
    margin: 0 auto;
    position: relative; }
    @media (max-width: 1279px) {
      .sticky__wrapper {
        width: 906px; } }
    @media (max-width: 1037px) {
      .sticky__wrapper {
        margin: 0 auto;
        width: calc(100% - 80px); } }
  .sticky__logo {
    position: absolute;
    left: 0;
    top: 12px; }
    .sticky__logo img {
      height: 40px; }
  .sticky .open-menu {
    color: #12818c;
    border-color: #12818c;
    position: absolute;
    right: 0;
    top: 9px; }
  .sticky.actif {
    transform: translateY(0%); }

.accordion {
  border-bottom: 2px solid #fff; }
  .accordion__headline {
    padding: 50px 0;
    padding-left: 5px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .accordion__headline-heading {
      font: 400 normal 26px 'Playfair Display';
      line-height: 2em;
      margin-left: 20px;
      position: relative; }
      .accordion__headline-heading::after {
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        left: 0;
        bottom: -15px;
        background-color: #fff;
        transition: all 0.5s; }
      .accordion__headline-heading.active::after {
        width: 150px;
        transition: all 0.5s; }
    .accordion__headline-button {
      cursor: pointer;
      background-color: transparent;
      color: #fff;
      border: 2px solid #fff;
      width: 45px;
      height: 45px;
      display: flex;
      font: 500 normal 16px/16px Roboto;
      justify-content: center;
      align-items: center;
      margin: 20px;
      transition: all 0.5s; }
      .accordion__headline-button:focus {
        outline: 0; }
      .accordion__headline-button:hover {
        background-color: #fff;
        color: #12818c; }
      .accordion__headline-button.active > div {
        transform: rotate(45deg);
        transition: all 0.5s; }
  .accordion__content {
    font: 300 normal 16px/26px Roboto;
    padding: 0 0 20px 5px;
    margin: 30px 0 0 20px;
    display: none; }
    .accordion__content-nobutton {
      font: 300 normal 16px/26px Roboto;
      padding: 0 0 40px 5px;
      margin: 0 0 50px 20px;
      display: block; }
    .accordion__content.visible {
      display: block; }

@media only screen and (max-width: 600px) {
  .accordion__headline {
    padding-left: 0; }
    .accordion__headline-heading {
      margin-left: 0;
      max-width: 210px;
      font-size: 20px; }
    .accordion__headline-button {
      margin: 0; } }

.slick-wrapper {
  position: relative;
  width: 450px;
  height: auto;
  margin: 50px auto;
  background-color: transparent; }
  @media (max-width: 530px) {
    .slick-wrapper {
      width: 350px; } }
  @media (max-width: 400px) {
    .slick-wrapper {
      width: 250px; } }
  .slick-wrapper .slide-item {
    color: #000;
    text-align: center;
    cursor: pointer; }
    .slick-wrapper .slide-item:focus {
      outline: none; }
    .slick-wrapper .slide-item__image {
      position: relative;
      width: 350px;
      height: 350px;
      margin: auto;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 530px) {
        .slick-wrapper .slide-item__image {
          width: 250px;
          height: 250px; } }
      @media (max-width: 400px) {
        .slick-wrapper .slide-item__image {
          width: 200px;
          height: 200px; } }
      .slick-wrapper .slide-item__image__magnifier {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: rgba(18, 129, 147, 0.5); }
        .slick-wrapper .slide-item__image__magnifier:before, .slick-wrapper .slide-item__image__magnifier:after {
          position: absolute;
          left: 25px;
          top: 10px;
          content: ' ';
          height: 30px;
          width: 2px;
          background-color: #fff; }
        .slick-wrapper .slide-item__image__magnifier:before {
          transform: rotate(90deg); }
    .slick-wrapper .slide-item__text {
      color: #fff;
      margin-top: 20px;
      font: 200 normal 16px/26px Roboto; }
  .slick-wrapper .slick-dots {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none; }
    .slick-wrapper .slick-dots li {
      margin: 0 2px; }
      .slick-wrapper .slick-dots li.slick-active button {
        background-color: #fff; }
    .slick-wrapper .slick-dots button {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: none;
      background-color: rgba(255, 255, 255, 0.2); }
  .slick-wrapper .controlbutton {
    position: absolute;
    top: 150px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 100; }
    @media (max-width: 530px) {
      .slick-wrapper .controlbutton {
        top: 100px; } }
    @media (max-width: 400px) {
      .slick-wrapper .controlbutton {
        top: 75px; } }
    .slick-wrapper .controlbutton--left  {
      left: 0;
      transform: rotate(135deg); }
      @media (max-width: 400px) {
        .slick-wrapper .controlbutton--left  {
          left: -40px; } }
      .slick-wrapper .controlbutton--left __inner {
        margin-left: 3px; }
    .slick-wrapper .controlbutton--right {
      right: 0;
      transform: rotate(-45deg); }
      @media (max-width: 400px) {
        .slick-wrapper .controlbutton--right {
          right: -40px; } }
      .slick-wrapper .controlbutton--right_inner {
        margin-right: 3px; }
    .slick-wrapper .controlbutton__inner {
      width: 20px;
      height: 20px;
      border-right: 3px solid rgba(255, 255, 255, 0.2);
      border-bottom: 3px solid rgba(255, 255, 255, 0.2);
      transition: all 0.5s; }
    .slick-wrapper .controlbutton:hover .controlbutton__inner {
      border-color: #fff;
      transition: all 0.5s; }
  .slick-wrapper .slick-wrapper__close {
    position: fixed;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    background-color: #fff;
    opacity: 0.8;
    transition: all 0.5s;
    display: none;
    z-index: 200; }
    .slick-wrapper .slick-wrapper__close:hover {
      opacity: 1;
      cursor: pointer; }
    .slick-wrapper .slick-wrapper__close:before, .slick-wrapper .slick-wrapper__close:after {
      position: absolute;
      left: 40px;
      top: 10px;
      content: ' ';
      height: 60px;
      width: 2px;
      background-color: #333; }
    .slick-wrapper .slick-wrapper__close:before {
      transform: rotate(45deg); }
    .slick-wrapper .slick-wrapper__close:after {
      transform: rotate(-45deg); }
  .slick-wrapper.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: #1281a0;
    z-index: 100; }
    .slick-wrapper.fullscreen .slick-wrapper__close {
      display: block; }
    .slick-wrapper.fullscreen .slide-item__image {
      width: 100vw;
      height: 70vh;
      margin-top: 40px;
      background-size: contain; }
      @media (max-width: 850px) {
        .slick-wrapper.fullscreen .slide-item__image {
          height: 60vh; } }
      @media (max-width: 650px) {
        .slick-wrapper.fullscreen .slide-item__image {
          width: 80vw;
          height: 50vh;
          margin-top: 20px; } }
      .slick-wrapper.fullscreen .slide-item__image__magnifier {
        display: none; }
    .slick-wrapper.fullscreen .slide-item__text {
      width: 50vw;
      margin: auto;
      margin-top: 20px; }
      @media (max-width: 1000px) {
        .slick-wrapper.fullscreen .slide-item__text {
          width: 80%; } }
      @media (max-width: 650px) {
        .slick-wrapper.fullscreen .slide-item__text {
          margin-top: 5px;
          font: 200 normal 14px/26px Roboto; } }
    .slick-wrapper.fullscreen .controlbutton {
      top: 30%;
      width: 150px;
      height: 150px; }
      @media (max-width: 650px) {
        .slick-wrapper.fullscreen .controlbutton--left {
          left: 8px; } }
      @media (max-width: 650px) {
        .slick-wrapper.fullscreen .controlbutton--right {
          right: 8px; } }
      @media (max-width: 650px) {
        .slick-wrapper.fullscreen .controlbutton {
          top: 25%;
          width: 30px;
          height: 30px; } }
      .slick-wrapper.fullscreen .controlbutton__inner {
        width: 80px;
        height: 80px; }
        @media (max-width: 650px) {
          .slick-wrapper.fullscreen .controlbutton__inner {
            width: 20px;
            height: 20px; } }
    .slick-wrapper.fullscreen .slick-dots {
      display: none; }
