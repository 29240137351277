body {
  height: 100vh;
  width: 100vw;
  background-color: $main;
  color: #ffffff;
  font: 200 normal 20px/24px 'Roboto';
  overflow: visible;
  &.noscroll {
    overflow: hidden;
  }
}

// font-family: 'Playfair Display', serif;
//font-family: 'Roboto', sans-serif;

#page__wrapper {
  width: 1090px;
  margin: 0 auto;
  position: relative;
  height: 100px;
  padding: 290px 0 0 200px;
  @media (max-width: 1279px) {
    padding: 290px 0 0 0;
    width: 906px;
  }

  @media (max-width: 767px) {
    padding: 160px 0 0 0;
  }

  @media (max-width: 1037px) {
    width: calc(100% - 80px);
  }
}
aside {
  position: fixed;
  top: 0;
  left: calc(50% - 545px);
  z-index: 1;
  width: 115px;
  @media (max-width: 1279px) {
    display: block;
    left: calc(50% - 453px);
    z-index: 10;
    position: absolute;
  }

  @media (max-width: 1037px) {
    left: 40px;
  }
}

a,
a:hover {
  color: #ffffff;
  text-decoration: none;
}
.navigation {
  &__main {
    margin-top: 180px;

    &-list {
      margin: 0;
      padding: 0;

      @media (max-width: 1279px) {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    &-item {
      list-style: none;
    }
    &-link {
      min-width: 130px;
      font: 700 normal 16px/42px 'Josefin Sans';
      display: block;
      font-family: 'Josefin Sans', sans-serif;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 1.2px;

      @media (max-width: 1279px) {
        font: 700 normal 25px/60px 'Josefin Sans';
      }

      span {
        position: relative;
        pointer-events: none;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #fff;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
      }
      &:hover span::after,
      &--active span::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
    @media (max-width: 1279px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      margin: 0;
      text-align: center;
      opacity: 0;
      z-index: 20;
    }
    .to_contact {
      font: 700 normal 16px/16px 'Josefin Sans';
      text-transform: uppercase;
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
      display: none;
      @media (max-width: 1279px) {
        display: inline-block;
      }
    }
  }
}

.top-navigation {
  position: absolute;
  top: 40px;
  right: 0;
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    list-style: none;
  }
  text-align: right;
  font: 700 normal 16px/16px 'Josefin Sans';
  font-family: 'Josefin Sans', sans-serif;
  display: block;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1.2px;
  &__link {
    position: relative;
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      top: 100%;
      height: 2px;
      background: #fff;
      left: 0;
      transform: scaleX(0);
      opacity: 0;
      transition: 0.3s;
      transform-origin: 0 0;
    }
    &:hover::after,
    &--active::after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
  @media (max-width: 1279px) {
    display: none;
  }
}

.logo {
  display: block;
  margin-top: 40px;
  img {
    height: 70px;
  }
}

h1 {
  font: 400 normal 48px/53px 'Playfair Display';
  padding: 0 156px 0 92px;
  @media (max-width: 1037px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    font: 400 normal 36px/46px 'Playfair Display';
  }
  @media (max-width: 365px) {
    font: 400 normal 28px/38px 'Playfair Display';
  }
}

.start {
  &__text {
    padding: 20px 156px 0 92px;
    @media (max-width: 1037px) {
      padding: 10px 0 0 0;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__content {
    background: #ffffff;
    margin: 70px 0 0;

    @media (max-width: 767px) {
      width: 100vw;
      margin: 60px 0 0 -40px;
      padding: 0px 40px;
    }

    color: $main;
    position: relative;
  }

  &__image {
    height: 564px;
    width: 830px;
    display: block;
    margin: -20px 0 0 -20px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: inline-block;

    @media (max-width: 1037px) {
      width: calc(100vw - 220px);
      height: calc((100vw - 220px) * 0.51);
    }

    @media (max-width: 767px) {
      margin-left: -20px;
      width: calc(100vw - 40px);
      height: calc((100vw - 40px) * 0.64);
    }

    &-cover {
      background-color: $main;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      overflow: hidden;
      opacity: 1;
      transform: translateX(-100%);
    }
    &-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: bottom;
      transform: translateX(96%);
    }
  }

  &__fond {
    display: block;
    width: 830px;
    height: 565px;
    background-color: $main;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;

    @media (max-width: 1037px) {
      width: calc(100vw - 219px);
      height: calc((100vw - 220px) * 0.51);
    }

    @media (max-width: 767px) {
      margin-left: 41px;
      width: calc(100vw - 40px);
      height: calc((100vw - 40px) * 0.64);
    }
  }
  &__body {
    padding: 96px 92px 96px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      margin-top: 70px;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 50px 40px 90px;
      display: block;
    }
    &-title {
      font: 400 normal 34px/45px 'Playfair Display';
      flex: 0 0 calc(40% - 20px);
      @media (max-width: 365px) {
        font: 400 normal 28px/38px 'Playfair Display';
      }
    }
    &-text {
      flex: 0 0 calc(60% - 20px);
      font: 300 normal 20px/24px 'Roboto';
      b {
        font-weight: 600;
      }
      @media (max-width: 365px) {
        font: 300 normal 18px/20px 'Roboto';
      }
      @media (max-width: 1037px) {
        flex: 0 0 calc(40% - 20px);
      }
    }
  }
}

.uber-uns {
  background-color: #fff;
  color: $main;
  margin: 20px 0 0;
  padding: 90px 92px 90px;
  position: relative;
  @media (max-width: 767px) {
    width: 100vw;
    margin-left: -40px;
    padding: 50px 40px 90px;
  }
  &__title {
    font: 400 normal 34px/45px 'Playfair Display';
    @media (max-width: 365px) {
      font: 400 normal 28px/38px 'Playfair Display';
    }
    position: relative;
    &::after {
      content: '';
      display: block;
      background-color: $main;
      opacity: 1;
      position: absolute;
      width: 52px;
      height: 2px;
      left: 0;
      bottom: -25px;
    }
  }
  &__text {
    padding: 26px 0 0 276px;
    font: 300 normal 20px/24px 'Roboto';
    @media (max-width: 365px) {
      font: 300 normal 18px/22px 'Roboto';
    }
    @media (max-width: 767px) {
      padding: 60px 0 0 0;
    }
  }

  &__person {
    display: flex;
    flex-wrap: nowrap;
    // white-space: nowrap;
    margin: 100px 0 0 -22px;
    @media (max-width: 767px) {
      margin: 50px 0 0 0;
    }
  }
  &__card {
    flex: 0 0 482px;

    @media (max-width: 1037px) {
      flex: 0 0 362px;
    }

    @media (max-width: 767px) {
      display: block;
      width: 100%;
      flex: none;
    }
    &-item {
      border: 2px solid $main;
      padding: 20px;
      cursor: pointer;
      position: relative;
      margin: -2px 0 0;
      width: 372px;

      @media (max-width: 1037px) {
        width: 322px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }

      @media (max-width: 365px) {
        padding: 10px;
      }

      &::after {
        position: absolute;
        pointer-events: none;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 5px solid #fff;
        content: '';
      }
      &--current,
      &:hover {
        border: 2px solid $main;
        z-index: 2;
        &::after {
          content: none;
        }
      }
    }
    &-photo {
      height: 74px;
      width: 74px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &-data {
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      padding: 0 0 0 20px;
      @media (max-width: 365px) {
        padding: 0 0 0 10px;
      }
    }
    &-title {
      font: 500 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
      padding-bottom: 4px;
    }
    &-subtitle {
      font: 300 normal 16px/19px 'Roboto';
    }
  }
  &__photo {
    flex: 0 0 420px;
    position: relative;

    @media (max-width: 1037px) {
      flex: 0 0 calc(100vw - 80px - 92px - 320px);
    }

    @media (max-width: 767px) {
      display: none;
    }
    overflow: hidden;
    &-cover {
      position: relative;
      height: 270px;
      @media (max-width: 1037px) {
        height: calc((100vw - 80px - 92px - 320px) * 0.64);
      }
      width: 100%;
      margin-top: -2px;
      // overflow: hidden;
      // transform: translateX(-100%);
    }
    &-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateX(-100%);
    }
    &-fond {
      background-color: $main;
      position: absolute;
      content: '';
      width: calc(100% - 20px);
      height: 20px;
      top: 100%;
      left: 20px;
      opacity: 1;
    }
    &-data {
      padding: 60px 0 5px;
    }
    &-title {
      font: 400 normal 24px/32px 'Playfair Display';
    }
    &-subtitle {
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
    }

    &-phone,
    &-email,
    &-xing,
    &-linkedin {
      margin-top: 5px;
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
      a {
        color: $main;
        position: relative;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: $main;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
        &:hover {
          color: $main;
        }
        &:hover::after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }

    &-xing,
    &-linkedin {
      display: inline-block;
      margin: 20px 10px 0 0;
      font-size: 25px;
    }
  }
  &__description {
    font: 300 normal 16px/26px 'Roboto';
    padding: 50px 0 100px 0;
    @media (max-width: 767px) {
      padding: 50px 0 50px 0;
    }
    @media (max-width: 365px) {
      font: 300 normal 16px/24px 'Roboto';
      padding: 25px 0 50px 0;
    }
  }
  &__to-contact {
    display: flex;
    padding-top: 50px;
    @media (max-width: 767px) {
      display: block;
    }

    h3 {
      font: 400 normal 24px/32px 'Playfair Display';
      @media (max-width: 767px) {
        font: 400 normal 22px/30px 'Playfair Display';
      }
    }
    & > div:last-child {
      flex: 0 0 200px;
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
  }

  &__contactMobile {
    display: none;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      display: block;
    }
    &-title {
      font: 400 normal 24px/32px 'Playfair Display';
    }
    &-subtitle {
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
    }

    &-phone,
    &-email,
    &-xing,
    &-linkedin {
      margin-top: 5px;
      font: 300 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
      a {
        color: $main;
        position: relative;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: $main;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
        &:hover {
          color: $main;
        }
        &:hover::after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }

    &-xing,
    &-linkedin {
      display: inline-block;
      margin: 20px 10px 0 0;
      font-size: 25px;
    }
  }
}

hr {
  border-top: 2px solid rgba($main, 0.2);
}

.button {
  color: $main;
  border: 2px solid $main;
  background-color: transparent;
  margin-top: 5px;
  font: 500 normal 16px/16px 'Roboto';
  padding: 10px 15px 11px;
  display: inline-block;
  white-space: nowrap;
  &:hover {
    background-color: $main;
    color: #ffffff;
    transition: 0.5s;
  }
}

.footer {
  display: flex;
  @media (max-width: 1037px) {
    display: block;
    padding: 0;
  }
  justify-content: space-between;
  padding: 62px 0 98px 90px;
  font: 500 normal 16px/19px 'Roboto';
  &__contact {
    &-email,
    &-phone {
      margin-top: 38px;
      @media (max-width: 1037px) {
        margin-top: 20px;
      }
      display: block;
      font: 500 normal 16px/19px 'Roboto';
      color: #ffffff;
      span {
        position: relative;
        pointer-events: none;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #fff;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
      }
      &:hover span::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
    @media (max-width: 1037px) {
      border-bottom: 2px solid rgba(white, 0.2);
      padding: 10px 0 30px 0;
    }
  }

  &__address {
    @media (max-width: 1037px) {
      border-bottom: 2px solid rgba(white, 0.2);
      padding: 10px 0 30px 0;
    }
    &-street,
    &-city {
      margin-top: 38px;
      @media (max-width: 1037px) {
        margin-top: 20px;
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1037px) {
      padding: 20px 0 30px 0;
    }

    a {
      display: inline-block;
      font: 500 normal 16px/19px 'Roboto';
      color: #ffffff;
      span {
        position: relative;
        pointer-events: none;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: #fff;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
      }
      &:hover span::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }
}

.leistugen {
  padding: 100px 0 90px 90px;
  @media (max-width: 1037px) {
    padding-left: 0;
  }
  &-list {
    @include make-row();
    // display: flex;
    // justify-content: space-between;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__item {
    // border-top: 2px solid #ffffff;
    // flex: 0 0 calc((100% - 36px) / 3);
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @media (max-width: 767px) {
      display: block;
      width: 100%;
      flex: none;
      padding-bottom: 25px;
    }

    &-title {
      border-top: 2px solid #ffffff;
      padding: 50px 0 4px;
      font: 400 normal 30px/36px 'Playfair Display';
      min-height: 175px;
      @media (max-width: 1037px) {
        font-size: 26px;
      }
      @media (max-width: 767px) {
        min-height: auto;
      }
    }
    &-subtitle {
      font: 500 normal 16px/23px 'Roboto';
      // span {
      //   font: 200 italic 16px/32px 'Roboto';
      // }
      span {
        font: 500 normal 16px/32px 'Roboto';
      }
      min-height: 95px;
    }
    &-link,
    &-link--accordion {
      @extend .button;
      color: white;
      border-color: white;
      margin-top: 30px;
      &:hover {
        background-color: #ffffff;
        color: $main;
        opacity: 1 !important;
      }
      &.active {
        span {
          display: block;
          transform: rotate(45deg);
          transition: 0.2s;
        }
      }
    }
  }

  &__container {
    .leistugen__item {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  &__content {
    display: none;
    // opacity: 0;
    &.show {
      display: block;
      opacity: 1;
      transition: 1s;
    }

    padding-top: 75px;

    &-title {
      font: 400 normal 34px/45px 'Playfair Display';
      @media (max-width: 1037px) {
        font-size: 26px;
      }
      position: relative;
      width: 40%;
      &::after {
        content: '';
        display: block;
        background-color: #fff;
        opacity: 1;
        position: absolute;
        width: 52px;
        height: 2px;
        left: 0;
        bottom: -25px;
      }
    }
    &-text {
      padding-top: 50px;
      font: 200 normal 16px/26px 'Roboto';
      &--underline {
        text-decoration: underline;
      }
    }
  }

  hr {
    border-top: 2px solid rgba(#ffffff, 0.2);
  }
  &__to-contact {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      display: block;
    }
    h3 {
      font: 400 normal 24px/32px 'Playfair Display';
      @media (max-width: 767px) {
        font: 400 normal 22px/30px 'Playfair Display';
      }
    }
    & > div:last-child {
      flex: 0 0 200px;
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
    .button {
      border-color: white;
      color: white;
      &:hover {
        background-color: #fff;
        color: $main;
      }
    }
  }

  &__whitepaper {
    background-color: #fff;
    color: #12818c;
    padding: 20px;
    margin-bottom:20px;
    .accordion {
      @media (max-width: 768px) {
        border-bottom: none;
      }
      &__headline {
        padding: 40px 0 40px 0;
      }
      &__headline-button {
        margin: 0;
        color: #12818c;
        border-color: #12818c;
      }
      &__headline-heading {
        margin-left: 0;
        max-width: none;
        @media (max-width: 400px) {
          max-width: 200px;
        }
        &::after {
          content: none;
        }
      }
      &__content {
        padding-left: 0;
        margin-left: 0;
        position: relative;
        p {
          margin-bottom: 70px;
        }
        .errormessage {
          color: red;
          margin-bottom: 20px;
          display: none;
          &.active {
            display: block;
          }
        }
        .step-1 {
          position: relative;
          display: block;
          form {
            width: 400px;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
          input {
            display: block;
            margin: 0 0 20px 0;
            padding: 5px 5px 5px 10px;
            font: 300 normal 24px/30px "Roboto";
            width: 100%;
            border: 2px solid #12818c;
            background-color: transparent;
            color: #12818c;
            outline: none;
            &::placeholder {
              color: #12818c;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: #12818c;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
              color: #12818c;
            }
            &.error {
              border-color: red;
              &::placeholder {
                color: red;
              }
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: red;
              }
              &::-ms-input-placeholder { /* Microsoft Edge */
                color: red;
              }
            }
          }
          .datenschutz {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            a {
              color: #12818c;
              text-decoration: underline;
            }
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              border: 2px solid #12818c;
              background-color: transparent;
              &::after {
                content: "";
                position: absolute;
                display: none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid #12818c;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
            input:checked ~ .checkmark:after {
              display: block;
            }
          }
          button.datenschutz-button {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 15px;
            font: 300 normal 24px/30px "Roboto";
            background-color: transparent;
            color: #12818c;
            border: 2px solid #12818c;
            outline: none;
            @media (max-width: 768px) {
              position: static;
              margin-left: 35px;
            }
          }

          button.datenschutz-button:disabled,
          button.datenschutz-button[disabled]{
            color: rgba(#12818c, 0.2);
            border-color: rgba(#12818c, 0.2);
          }
        }
        .step-2 {
          display: none;
          scroll-padding-top: 100px;
          .progressbar {
            position: relative;
            width: 100%;
            height: 25px;
            background-color: transparent;
            border: 2px solid #12818c;
            border-radius: 10px;
            overflow: hidden;
          }
          .progressbar-inner{
            position: absolute;
            width: 0%;
            height: 25px;
            background-color: #12818c;
          }
          .download-steps {
            display: flex;
            justify-content: space-between;
            margin: 50px 0 0px 0;
            > div {
              width: 30%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              text-align: center;
              .icon {
                margin-bottom: 30px;
              }
            }
            @media (max-width: 610px) {
              flex-direction: column;
              align-items: center;
              > div {
                width: 70%;
                margin-bottom: 40px;
                .icon {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.whitepaper-title {
  font: 400 normal 30px/60px 'Playfair Display';
}
.leistugen__whitepaper .accordion__content p {
  margin-bottom: 20px;
}

.kompetenzen {
  margin-bottom: 50px;
  padding: 50px 0 90px 90px;
  @media (max-width: 1037px) {
    padding-left: 0;
  }

  &--whitebg {
    margin-bottom: 0;
    background-color: #fff;
    color: $main;
    .kompetenzen__title {
      position: relative;
      font: 400 normal 34px/45px 'Playfair Display';
      &::after {
        content: '';
        display: block;
        background-color: $main;
        opacity: 1;
        position: absolute;
        width: 52px;
        height: 2px;
        left: 0;
        bottom: -25px;
      }
    }
    .kompetenzen__text {
      padding: 40px 130px 0 0;
      font: 300 normal 20px/24px Roboto;
    }
    @media (max-width: 1037px) {
      padding-left: 30px;
    }
  }
}

.contact {
  display: none;
  opacity: 0;
  color: $main;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
  background-color: #fff;

  &__wrapper {
    width: 1090px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 1037px) {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  &__logo {
    position: absolute;
    top: 40px;
    left: 0;
    @media (max-width: 1037px) {
      left: 40px;
    }
    img {
      height: 70px;
    }
  }
  &__button {
    @extend .button;
    position: absolute;
    right: 0;
    top: 53px;
    span {
      display: block;
      transform: rotate(45deg);
    }
    @media (max-width: 1037px) {
      right: 40px;
    }
  }
  &__content {
    padding-top: 48vh;
    display: flex;
    @media (max-width: 1037px) {
      padding-top: 30vh;
      display: block;
    }
  }
  &__title {
    font: 400 normal 48px/53px 'Playfair Display';
    position: relative;
    margin-top: -70px;
    margin-right: 90px;
    @media (max-width: 365px) {
      margin-top: -50px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 52px;
      height: 2px;
      background-color: $main;
      left: 0;
      bottom: 125px;
      @media (max-width: 1037px) {
        bottom: -20px;
      }
    }
  }
  &__info {
    margin-right: 90px;
    @media (max-width: 365px) {
      margin-right: 0px;
    }
    @media (max-width: 1037px) {
      margin-top: 50px;
    }
    &-title {
      font: 400 normal 24px/32px 'Playfair Display';
    }
    &-email,
    &-phone {
      display: block;
      font: 500 normal 16px/42px 'Roboto';

      margin: 13px 0 0;
      color: $main;
      span {
        position: relative;
        pointer-events: none;
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          top: 100%;
          height: 2px;
          background: $main;
          left: 0;
          transform: scaleX(0);
          opacity: 0;
          transition: 0.3s;
          transform-origin: 0 0;
        }
      }
      &:hover {
        color: $main;
      }
      &:hover span::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }
  &__address {
    margin-right: 90px;
    @media (max-width: 1037px) {
      margin-top: 30px;
    }
    &-title {
      font: 400 normal 24px/32px 'Playfair Display';
    }
    &-street,
    &-city {
      display: block;
      font: 500 normal 16px/42px 'Roboto';
      margin: 13px 0 0;
    }
  }
}

.open-menu {
  @extend .button;
  color: white;
  border-color: white;
  transition: 0.3s;
  &:hover {
    background-color: #ffffff;
    color: $main;
  }
  position: absolute;
  right: calc(50% - 545px);
  top: 53px;
  z-index: 1;
  display: none;

  @media (min-width: 1280px) {
    display: none !important;
  }

  @media (max-width: 1279px) {
    display: block;
    right: calc(50% - 453px);
  }

  @media (max-width: 1037px) {
    right: 40px;
  }
}

.close-menu {
  @extend .button;
  color: white;
  border-color: white;
  transition: 0.3s;
  &:hover {
    background-color: #ffffff;
    color: $main;
  }
  span {
    display: block;
    transform: rotate(45deg);
  }
  display: none;
  position: absolute;
  right: calc(50% - 545px);
  top: 53px;
  z-index: 1;

  @media (min-width: 1280px) {
    display: none !important;
  }

  @media (max-width: 1279px) {
    display: block;
    right: calc(50% - 453px);
  }

  @media (max-width: 1037px) {
    right: 40px;
  }
}

.impressum {
  &__box {
    background-color: #fff;
    color: $main;
    margin: 20px 0 0;
    padding: 90px 92px 90px;
    position: relative;
    @media (max-width: 767px) {
      width: 100vw;
      margin-left: -40px;
      padding: 50px 40px 90px;
    }
    display: flex;
    flex-wrap: nowrap;
  }
  &__contact {
    flex: 0 0 482px;

    @media (max-width: 1037px) {
      flex: 0 0 342px;
    }

    @media (max-width: 767px) {
      display: block;
      width: 100%;
      flex: none;
    }
  }
  &__title {
    font: 400 normal 34px/45px 'Playfair Display';
    @media (max-width: 365px) {
      font: 400 normal 28px/38px 'Playfair Display';
    }
    position: relative;
    &::after {
      content: '';
      display: block;
      background-color: $main;
      opacity: 1;
      position: absolute;
      width: 52px;
      height: 2px;
      left: 0;
      bottom: -25px;
    }
  }
  &__text {
    padding: 50px 0 0 0;
    font: 300 normal 20px/24px 'Roboto';
    a {
      color: $main;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 100%;
        height: 2px;
        background: $main;
        left: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: 0.3s;
        transform-origin: 0 0;
      }
      &:hover {
        color: $main;
      }
      &:hover::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
    @media (max-width: 365px) {
      font: 200 normal 18px/22px 'Roboto';
    }
    @media (max-width: 767px) {
      padding: 60px 0 0 0;
    }
  }

  &__photo {
    flex: 0 0 420px;
    position: relative;

    @media (max-width: 1037px) {
      flex: 0 0 calc(100vw - 80px - 92px - 320px);
    }

    @media (max-width: 767px) {
      display: none;
    }

    &-cover {
      position: relative;
      height: 270px;
      @media (max-width: 1037px) {
        height: calc((100vw - 80px - 92px - 320px) * 0.64);
      }
      width: 100%;
      margin-top: -2px;
    }
    &-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-fond {
      background-color: $main;
      position: absolute;
      content: '';
      width: calc(100% - 20px);
      height: 20px;
      top: 100%;
      left: 20px;
      opacity: 1;
    }
    &-data {
      padding: 60px 0 5px;
    }
    &-title {
      font: 400 normal 24px/32px 'Playfair Display';
    }
    &-subtitle {
      font: 200 normal 16px/19px 'Roboto';
      letter-spacing: 0.5px;
    }
  }
  &__content {
    color: #fff;
    font-family: 'Roboto';
    padding: 90px 92px 90px;
    position: relative;
    @media (max-width: 767px) {
      width: 100vw;
      margin-left: -40px;
      padding: 50px 40px 90px;
    }
    h2 {
      font: 400 normal 34px/45px 'Playfair Display';
      @media (max-width: 1037px) {
        font: 400 normal 28px/38px 'Playfair Display';
      }
      @media (max-width: 365px) {
        font: 400 normal 26px/38px 'Playfair Display';
      }
      position: relative;
      &::after {
        content: '';
        display: block;
        background-color: #fff;
        opacity: 1;
        position: absolute;
        width: 52px;
        height: 2px;
        left: 0;
        bottom: -25px;
      }
      margin-bottom: 50px;
    }

    h4 {
      font-weight: bold;
      font-style: italic;
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 67px;
  background-color: #fff;
  z-index: 9;
  transition: 0.2s;
  opacity: 0;
  transform: translateY(-100%);

  &__wrapper {
    width: 1090px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 1279px) {
      width: 906px;
    }
    @media (max-width: 1037px) {
      margin: 0 auto;
      width: calc(100% - 80px);
    }
  }
  &__logo {
    position: absolute;
    left: 0;
    top: 12px;
    img {
      height: 40px;
    }
  }
  .open-menu {
    color: $main;
    border-color: $main;
    position: absolute;
    right: 0;
    top: 9px;
  }
  &.actif {
    transform: translateY(0%);
  }
}
